<template>
    <v-dialog v-model="appCharts.showModalMp" transition="dialog-bottom-transition" fullscreen>
        <v-container fluid>
            <v-card>
                <Pareto_motivo_de_parada />
            </v-card>
        </v-container>
    </v-dialog>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import Pareto_motivo_de_parada from "./pareto_motivo_de_parada.vue";

export default {
    name : "motivo_de_parada",
    components: {
        Pareto_motivo_de_parada,
    },

    data: () => {

    },
    computed : {
        appCharts() {
            return useAppCharts()
        }
    },
}


</script>