import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "control-section" }
const _hoisted_2 = { align: "center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_e_series = _resolveComponent("e-series")
  const _component_e_series_collection = _resolveComponent("e-series-collection")
  const _component_ejs_chart = _resolveComponent("ejs-chart")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appCharts.showGabriel,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.appCharts.showGabriel) = $event)),
    transition: "dialog-bottom-transition",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, { fluid: "" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_btn, { onClick: $options.closeDialog }, {
              default: _withCtx(() => [
                _createTextVNode("close")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_card, { style: {"background-color":"white"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_ejs_chart, {
                    style: {"display":"block"},
                    theme: _ctx.theme,
                    align: "center",
                    id: "chartcontainer",
                    title: _ctx.title,
                    primaryXAxis: _ctx.primaryXAxis,
                    primaryYAxis: _ctx.primaryYAxis,
                    chartArea: _ctx.chartArea,
                    width: _ctx.width,
                    legendSettings: _ctx.legendSettings,
                    tooltip: _ctx.tooltip
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_e_series_collection, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_e_series, {
                            dataSource: _ctx.seriesData,
                            type: "Pareto",
                            xName: "x",
                            yName: "y",
                            name: "Quantidade de Horas",
                            width: "2",
                            columnWidth: "0.4",
                            paretoOptions: _ctx.paretoOptions,
                            cornerRadius: _ctx.cornerRadius,
                            fill: '#121935'
                          }, null, 8 /* PROPS */, ["dataSource", "paretoOptions", "cornerRadius"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["theme", "title", "primaryXAxis", "primaryYAxis", "chartArea", "width", "legendSettings", "tooltip"])
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}