<template>
    <v-dialog v-model="appCharts.show_disponibilidade_resumida" transition="dialog-bottom-transition" fullscreen>
        <v-container fluid>
            <v-card height="">
                <v-toolbar color="primary">
                    <v-btn icon="mdi-close" @click="appCharts.show_disponibilidade_resumida = false"></v-btn>
                    <v-btn class="" @click="dialog = true">Abrir</v-btn>
                </v-toolbar>
                <v-dialog v-model="dialog" max-width="600" height="600">
                    <v-card class="pt-1 pb-1 pl-1 pr-1" max-width="450" height="300">
                        <v-container fluid>
                            <v-row class="mt-0 mb-0 pt-0 pb-0">
                                <v-col cols='12' class="mt-0 mb-0 pt-0 pb-0">
                                    <h3>Filtrado por:</h3>
                                    <label class="h4">Por período: </label>
                                    <ejs-daterangepicker
                                        style="height: 40px; font-size: 16px; font-weight: 600; width: 100%;"
                                        :format="'dd/MM/yyyy'" :value="defaultDateRange"
                                        @change="onDateChange"></ejs-daterangepicker>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0 mb-0 pt-0 pb-0">
                                <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
                                    <div class="control-styles" style="padding-top: 30px">
                                        <label class="h4">Por Tempo: </label>
                                        <ejs-multiselect id='sample-list3' :dataSource='sportsData'
                                            :mode='delimiterMode' :placeholder='waterMark'></ejs-multiselect>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="mt-0 mb-0 pt-0 pb-0">
                                <v-btn color="green">Aplicar</v-btn>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>

                <v-divider class="mb-2 mt-2"></v-divider>
                <pie_disponibilidede_resumida />

                <tabela_disponibilidade_resumida />
            </v-card>
        </v-container>
    </v-dialog>
</template>

<script>
import { useAppCharts } from '../../../stores/appCharts';
import tabela_disponibilidade_resumida from '../charts/tabela_disponibilidade_resumida.vue'
import { DateRangePickerComponent } from '@syncfusion/ej2-vue-calendars';
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import pie_disponibilidede_resumida from './pie_disponibilidede_resumida.vue';


export default {
    name: 'disponibilidade_resumida',
    components: {
        tabela_disponibilidade_resumida,
        'ejs-daterangepicker': DateRangePickerComponent,
        'ejs-multiselect': MultiSelectComponent,
        pie_disponibilidede_resumida
    },
    data() {
        return {
            dialog: false,
            waterMark: 'Favorite Sports',
            defaultMode: 'Default',
            boxMode: 'Box',
            delimiterMode: 'Delimiter',
            sportsData: ['sportsData', '@teste']
        }
    },
    computed: {
        appCharts() {
            return useAppCharts()
        }
    },

}
</script>