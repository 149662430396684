<template>
    <v-dialog v-model="appCharts.showModal" transition="dialog-bottom-transition" fullscreen>
        <v-container fluid style="width: 100%;">
            <v-toolbar color="primary">
                <v-btn icon="mdi-close" @click="appCharts.showModal = false"></v-btn>
                <v-toolbar-title>MINHA FABRICA</v-toolbar-title>
            </v-toolbar>

            <v-card style="height: 80vh;">
                <v-row justify="start;" class="p-3">
                    <v-col cols="4">
                        <v-card class="d-flex flex-column justify-end align-center" style="height: 150px;"
                            color="primary" @click="open_pareto">
                            <v-icon icon="mdi-chart-bar" size="100"></v-icon>
                            <v-card-title class="text-white text-center">PARETO DAS PARADAS</v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card class="d-flex flex-column justify-end align-center" style="height: 150px;"
                            color="primary" @click="open_disponibilidade_resumida">
                            <v-icon icon="mdi-chart-donut" size="100"></v-icon>
                            <v-card-title class="text-white text-center">DISPONIBILIDADE
                                RESUMIDA</v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card @click="onclickStatus" class="d-flex flex-column justify-end align-center"
                            style="height: 150px;" color="primary">
                            <v-icon icon="mdi-cog-box" size="100"></v-icon>
                            <v-card-title class="text-white text-center">MÁQUINA POR STATUS</v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card class="d-flex flex-column justify-end align-center" style="height: 150px;"
                            color="primary">
                            <v-icon icon="mdi-finance" size="100"></v-icon>
                            <v-card-title class="text-white text-center">DESEMPENHO DA EMPRESA</v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card class="d-flex flex-column justify-end align-center" @click="dev_gabriel" style="height: 150px;"
                            color="primary">
                           
                            <v-card-title class="text-white text-center">GABRIEL DEV</v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card class="d-flex flex-column justify-end align-center" @click="dev_rebeka" style="height: 150px;"
                            color="primary">
                           
                            <v-card-title class="text-white text-center">REBEKA DEV</v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </v-dialog>
</template>

<script>
import { useAppCharts } from '../../../stores/appCharts';

export default {
    name: "view_charts",

    data() { return { ialog: true, } },

    methods: {
        open_pareto() {
            useAppCharts().showModalMp = true
        },

        open_disponibilidade_resumida() {
             useAppCharts().show_disponibilidade_resumida = true
        },

        onclickStatus() {
            useAppCharts().status_atual_fabrica()
            useAppCharts().open_disponibilidade_resumida = true

        },
        dev_gabriel(){
            useAppCharts().showGabriel = true
        },

        dev_rebeka() {
            useAppCharts().showRebeka = true
        }
    },

    computed: { appCharts() { return useAppCharts(); } },
}
</script>

<style></style>